import { useState } from 'react'
import AuthFormStep1 from '../components/Auth/AuthFormStep1'
import AuthFormStep2 from '../components/Auth/AuthFormStep2'
import useTitle from "../hooks/useTitle"
import useCanonical from "../hooks/useCanonical"

const AuthView = ({ tab }) => {
  useTitle('Вход / регистрация')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + tab)

  const [step, setStep] = useState(1)
  const [phoneCode, setPhoneCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneCountry, setPhoneCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')

  return (
    <div className="container">
      <AuthFormStep1 active={step == 1} setStep={setStep} tab={tab} setPhone={setPhone} setPhoneCode={setPhoneCode} setPhoneNumber={setPhoneNumber} setPhoneCountry={setPhoneCountry} setFirstName={setFirstName} />
      <AuthFormStep2 active={step == 2} setStep={setStep} phone={phone} phoneCode={phoneCode} phoneNumber={phoneNumber} phoneCountry={phoneCountry} firstName={firstName} />
    </div>
  )
}

export default AuthView