export const DELIVERY_TYPES = Object.freeze({
  COURIER_DALLI: Symbol("Курьером Dalli"),
  COURIER_CDEK: Symbol("Курьером СДЭК"),
  COURIER_YANDEX: Symbol("Курьером Яндекс"),
  PICKUP_STORE: Symbol("Самовывоз из кофейни"),
  PICKUP_YANDEX: Symbol("Самовывоз из ПВЗ Яндекс"),
  PICKUP_CDEK: Symbol("Самовывоз из ПВЗ СДЭК"),
  RUSSIAN_POST: Symbol("Почтой России"),
})

export const PAYMENT_TYPES = Object.freeze({
  CARD: Symbol("Картой"),
  WIRE: Symbol("Банковский перевод"),
})

export const REVIEW_CATEGORIES = Object.freeze({
  PRODUCT: Symbol("О продукции"),
  DELIVERY: Symbol("О доставке"),
  STORE: Symbol("О магазине"),
  PAYMENT: Symbol("Об оплате"),
  TEAM: Symbol("О команде"),
})

export const BREWING_METHODS = Object.freeze({
  DRIP: Symbol("Дрип"),
  IMMERSION: Symbol("Иммерсия"),
  AEROPRESS: Symbol("Аэропресс"),
  FUNNEL: Symbol("Воронка"),
  GEYSER: Symbol("Гейзер"),
  DRIP_COFFEE_MAKER: Symbol("Кофеварка капельная"),
  COFFEE_MACHINE: Symbol("Кофемашина"),
  TURK: Symbol("Турка"),
  FRENCH_PRESS: Symbol("Френч-пресс"),
  CUP: Symbol("Чашка"),
  CAPSULE: Symbol("Капсула"),
})

export const CATALOG_SORT = Object.freeze({
  DEFAULT: Symbol("По умолчанию"),
  PRICE_ASC: Symbol("Цена: по возрастанию"),
  PRICE_DESC: Symbol("Цена: по убыванию"),
  NEWEST: Symbol("Сначала новые"),
  OLDEST: Symbol("Сначала старые"),
})

export const deliveryTypes = [
  { type: DELIVERY_TYPES.COURIER_DALLI, slug: "courier_dalli", label: "Курьером Dalli" },
  { type: DELIVERY_TYPES.COURIER_CDEK, slug: "courier_cdek", label: "Курьером СДЭК" },
  { type: DELIVERY_TYPES.COURIER_YANDEX, slug: "courier_yandex", label: "Курьером Яндекс" },
  { type: DELIVERY_TYPES.PICKUP_STORE, slug: "pickup_store", label: "Самовывоз из кофейни" },
  { type: DELIVERY_TYPES.PICKUP_YANDEX, slug: "pickup_yandex", label: "Самовывоз из ПВЗ Яндекс" },
  { type: DELIVERY_TYPES.PICKUP_CDEK, slug: "pickup_cdek", label: "Самовывоз из ПВЗ СДЭК" }, // , note: "Сроки доставки могут быть увеличены из-за повышенной загруженности транспортной компании"
  { type: DELIVERY_TYPES.RUSSIAN_POST, slug: "russian_post", label: "Почтой России", note: "Доставка осуществляется в ближайшее к вам почтовое отделение" },
]

export const deliveryTypeElectronicProductSlug = "electronic_product"

export const paymentTypes = [
  { type: PAYMENT_TYPES.CARD, slug: "card", label: "Картой" },
  { type: PAYMENT_TYPES.WIRE, slug: "wire", label: "Банковский перевод" },
]

export const reviewCategories = [
  { type: REVIEW_CATEGORIES.PRODUCT, slug: "product", label: "О продукции", id: 1 },
  { type: REVIEW_CATEGORIES.DELIVERY, slug: "delivery", label: "О доставке", id: 2 },
  { type: REVIEW_CATEGORIES.STORE, slug: "store", label: "О магазине", id: 3 },
  { type: REVIEW_CATEGORIES.PAYMENT, slug: "payment", label: "Об оплате", id: 4 },
  { type: REVIEW_CATEGORIES.TEAM, slug: "team", label: "О команде", id: 5 },
]

export const brewingMethods = [
  { type: BREWING_METHODS.DRIP, slug: "drip", label: "Дрип" },
  { type: BREWING_METHODS.IMMERSION, slug: "immersion", label: "Иммерсия" },
  { type: BREWING_METHODS.AEROPRESS, slug: "aeropress", label: "Аэропресс" },
  { type: BREWING_METHODS.FUNNEL, slug: "funnel", label: "Воронка" },
  { type: BREWING_METHODS.GEYSER, slug: "geyser", label: "Гейзер" },
  { type: BREWING_METHODS.DRIP_COFFEE_MAKER, slug: "drip-coffee-maker", label: "Капельная кофеварка" },
  { type: BREWING_METHODS.COFFEE_MACHINE, slug: "coffee-machine", label: "Кофемашина" },
  { type: BREWING_METHODS.TURK, slug: "turk", label: "Турка" },
  { type: BREWING_METHODS.FRENCH_PRESS, slug: "french-press", label: "Френч-пресс" },
  { type: BREWING_METHODS.CUP, slug: "cup", label: "Чашка" },
  { type: BREWING_METHODS.CAPSULE, slug: "capsule", label: "Капсула" },
]

export const catalogSort = [
  { type: CATALOG_SORT.DEFAULT, slug: "default", label: "По умолчанию" },
  { type: CATALOG_SORT.PRICE_ASC, slug: "price-asc", label: "Цена: по возрастанию" },
  { type: CATALOG_SORT.PRICE_DESC, slug: "price-desc", label: "Цена: по убыванию" },
  { type: CATALOG_SORT.NEWEST, slug: "newest", label: "Сначала новые" },
  { type: CATALOG_SORT.OLDEST, slug: "oldest", label: "Сначала старые" },
]

export const SLUG_KNOWLEDGE = "knowledge"
export const SLUG_KNOWLEDGE_CAT_ARTICLES = "articles"
export const SLUG_COUNTRIES = "countries"
export const SLUG_CAT_WEEKS_CHOICE = "weeks-choice"
export const SLUG_CAT_NEW = "new"
export const SLUG_CAT_COFFEE_DRIPS = "coffee-drips"
export const SLUG_CAT_ARCHIVE = "archive"
export const PRODUCT_ID_FREE_DRIP_BAG = 18286
export const PRODUCT_ID_GIFT_CERTIFICATE = 99999999
export const SLUG_ATTR_BITTERNESS = "bitterness"
export const SLUG_ATTR_SWEETNESS = "sweetness"
export const SLUG_ATTR_ACIDITY = "acidity"
export const SLUG_ATTR_BREWING_METHOD = "brewing-method"
export const SLUG_FILTER_PERKS = "perks"
export const SLUG_SETTING_RESTRICTED_DATES = "restricted-dates"