import AboutUsTeam from './AboutUsTeam'
import useTitle from "../../hooks/useTitle"
import useCanonical from "../../hooks/useCanonical"

const AboutUsView = () => {
  useTitle('О нас')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'about-us')

  return (
    <div className="container">
      <div className="heading-h1">Rockets.coffee на<br />рынке с 2014 года</div>
      <div className="mb-40"><p>Мы сотрудничаем более чем с 500<br />заведениями и поставили более 300<br />комплектов оборудования</p></div>
      <div className="about-us-block mb-60">
        <div className="flex-padding">
          <div className="tab-link active">История</div>
        </div>
        <div>
          <div className="heading-h2-branded-bold mt-0">Rockets.coffee это</div>
          <p>Ежедневная обжарка зерна на собственном производстве в Москве, где качество продукта контролируется специалистами SCA. Персональный менеджер, своя служба доставки, полное техническое сопровождение, тренинг-центр для разных уровней, предоставление оборудования в аренду.</p>
        </div>
      </div>
      <div className="about-us-image-big mb-60">
        <img src="/images/about-us/img-1.jpg" alt="" />
      </div>
      <div className="about-us-block mb-60">
        <div className="flex-padding"></div>
        <div>
          <div className="heading-h2-branded-bold">Наши партнёры</div>
          <p>Мы работаем с крупными сетевыми заведениями, небольшими кофейнями и кафе, популярными барами и ресторанами в Москве и по всей России, а также поставляем кофе в офисы. Нашими клиентами являются такие компании как: GARAGE, Гольф-клуб Сколково, J'PAN, Friends forever, Gentle, Ribambele, Кухня на районе, Remy kitchen bakery, Булочные Фокина, White fox, OMG coffee, Пан Запекан, Cutfish, Muse, Pino, Coffeesphere, Батон, Bodrero, T&W. Monster coffee, Probka Family, Без рецепта, Mina и многие другие.</p>
        </div>
      </div>
      <div className="about-us-block mb-60">
        <div>
          <div className="about-us-image">
            <img src="/images/about-us/img-2.jpg" alt="" />
          </div>
        </div>
        <div>
          <div className="about-us-image">
            <img src="/images/about-us/img-3.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="about-us-block mb-60">
        <div className="flex-padding"></div>
        <div>
          <div className="heading-h2-branded-bold">Производство</div>
          <p>Ежедневно мы обжариваем 30 разновидностей кофейных зерен, доставляем их нашим клиентам, а также используем в работе собственных заведений.</p>
        </div>
      </div>
      <div className="about-us-image-big mb-60">
        <img src="/images/about-us/img-4.webp" alt="" />
      </div>
      <div className="about-us-block mb-60">
        <div className="flex-padding"></div>
        <div>
          <div className="heading-h2-branded-bold">Оборудование</div>
          <p>Основным преимуществом и отличительной чертой вкусовой составляющей компании Rockets является конвекционный ростер. Благодаря практически полному отсутствию кондукции при обжарке, наш кофе получается невероятно чистым, ароматным и легким.</p>
        </div>
      </div>
      <div className="about-us-image-big mb-60">
        <img src="/images/about-us/img-5.jpg" alt="" />
      </div>
      {/* <div className="about-us-heading-container">
        <div className="heading-h2-branded-bold">Наша команда</div>
      </div>
      <AboutUsTeam /> */}
    </div>
  )
}

export default AboutUsView