import { useDispatch, useSelector } from "react-redux"
import { lastOrderSet, selectLastOrder } from "../../features/lastOrder/lastOrderSlice"
import { Link } from "react-router-dom"
import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import useTitle from "../../hooks/useTitle"
import useCanonical from "../../hooks/useCanonical"

const ThankYouView = () => {
  useTitle('Спасибо за заказ!')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'thank-you')

  const { data: user } = useGetUserQuery()

  const order = useSelector(selectLastOrder)

  console.log(order)

  if (order === null) {
    return (
      <div className="container">
        <div className="heading-h1">Спасибо за заказ!</div>
        <p>Ваш заказ успешно оформлен.</p>
      </div>
    )
  }

  const formatDeliveryAddress = () => {
    if (order?.shipping_options?.length === 0 || !order.shipping_options || !order.shipping_options[0].pivot) {
      return ''
    }

    const possibleValues = [
      order.shipping_options[0].pivot.city,
      order.shipping_options[0].pivot.address,
      order.shipping_options[0].pivot.pickup_cdek,
      order.shipping_options[0].pivot.pickup_yandex,
      order.shipping_options[0].pivot.pickup_store,
    ]

    return possibleValues.filter(value => value).join(', ')
  }

  const getItemImage = (item) => {
    if (item?.product && item.product.variations[0]?.images[0]?.path) {
      return import.meta.env.VITE_BACKEND_URL + item.product.variations[0]?.images[0]?.path
    }
    return // TODO return no image?
  }

  return (
    <div className="container">
      <div className="thankyou-wrapper">
        <div className="thankyou-container">
          <div className="thankyou-image">
            <img src="/images/utils/thankyou.svg" alt="" />
          </div>
          <div className="thankyou-heading">Спасибо за покупку!</div>
          <p>Ваш заказ #{order.id} успешно создан {new Date(order.created_at).toLocaleDateString()} в {new Date(order.created_at).toLocaleTimeString()}</p>
          <div className="thankyou-items">
            {order.variations.map((variation, index) => (
              <div key={index} className="thankyou-item">
                <div className="thankyou-item-image">
                  <img src={getItemImage(variation)} alt="" />
                </div>
                <div className="thankyou-item-info">
                  <div className="thankyou-item-name">{variation.product.name}</div>
                  {variation.options?.length > 0 && <div className="thankyou-item-details">
                    {variation.options.map((option, index) => option.name).join(', ')}
                  </div>}
                  <div className="thankyou-item-qty-price">{variation.pivot.qty} x {variation.pivot.price / 100} <span className="commissioner-400">₽</span></div>
                </div>
              </div>
            ))}
          </div>
          <div className="thankyou-summary">
            <div className="thankyou-summary-line">
              <div>Способ оплаты</div>
              <div>{order.payment_method.name}</div>
            </div>
            <div className="thankyou-summary-line">
              <div>Адрес доставки</div>
              <div>{formatDeliveryAddress()}</div>
            </div>
            <div className="thankyou-summary-line">
              <div>Итого</div>
              <div>{order.total / 100} <span className="commissioner-400">₽</span></div>
            </div>
          </div>
          <p>При возникновении вопросов обращайтесь на наш email <span className="branded-text">info@rockets.coffee</span> или по телефону <span className="branded-text">8 (800) 777 04 14</span></p>
          {user?.id && <Link to="/me#history" className="button-big-primary-wide">Перейти к заказам</Link>}
        </div>
      </div>
    </div>
  )
}

export default ThankYouView