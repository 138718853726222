import { useState } from "react"
import MenuLink from "../MenuLink"
import MenuLinkDropdown from "../MenuLinkDropdown"
import SearchForm from "../SearchForm"

const Menu = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  return (
    <>
      <SearchForm isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
      <div className="menu-link menu-link__search hide-xxl hide-xl hide-lg hide-md" onClick={() => setIsSearchOpen(!isSearchOpen)}>
        <img src="/images/icons/search.svg" alt="" />
        <div>Поиск</div>
      </div>
      <MenuLinkDropdown label="Каталог">
        {/* <MenuLink to="/catalog/chernaya-pyatnica" label="Black Friday" /> */}
        <MenuLink to="/catalog/weeks-choice" label="Выбор недели" />
        {/* <MenuLink to="/catalog/new-year" label="Новый год" /> */}
        <MenuLink to="/catalog/coffee" label="Кофе в зёрнах" />
        <MenuLink to="/catalog/coffee-drips" label="Кофе в дрип-пакетах" />
        <MenuLink to="/catalog/coffee-capsules" label="Кофе в капсулах" />
        <MenuLink to="/catalog/tea" label="Чай Keen Tiger" />
        <MenuLink to="/catalog/matcha" label="Матча" />
        <MenuLink to="/catalog/notcoffee" label="Не кофе" />
        <MenuLink to="/catalog/accessories" label="Аксессуары" />
        <MenuLink to="/catalog/archive" label="Архив" />
      </MenuLinkDropdown>
      {/* <MenuLink to="/catalog/new-year" label="Новый год" className="hide-lg hide-xl" /> */}
      <MenuLink to="/gift-certificate" label="Подарочный сертификат" className="hide-lg hide-xl" />
      <MenuLink to="/shipping-and-payment" label="Доставка и оплата" />
      <MenuLink to="https://b2b-rockets.coffee/" label="Сотрудничество" target="_blank" />
      <MenuLink to="/loyalty-system" label="Бонусная система" />
      <MenuLink to="/coffee-shops" label="Наши проекты" className="hide-lg hide-xl hide-xxl" />
      <MenuLink to="/faq" label="FAQ" className="hide-lg hide-xl hide-xxl" />

      <MenuLink to="/about-us" label="О нас" className="hide-lg hide-xl hide-xxl" />
      <MenuLink to="/reviews" label="Отзывы" className="hide-lg hide-xl hide-xxl" />

      <MenuLinkDropdown label="Rockets.school">
        <MenuLink to="/education-all" label="Курсы и мастер-классы" />
        <MenuLink to="/knowledge" label="Статьи и способы заваривания" />
        <MenuLink to="/coffee-dictionary" label="Кофейный словарь" />
      </MenuLinkDropdown>
      <MenuLink to="/contacts" label="Контакты" className="hide-lg" />

      <MenuLinkDropdown arrow={false} label={<div className="menu-dots">
          <div className="menu-dot" />
          <div className="menu-dot" />
          <div className="menu-dot" />
        </div>}>
        <MenuLink to="/gift-certificate" label="Подарочный сертификат" className="hide-xs hide-sm hide-md hide-xxl" />
        <MenuLink to="/coffee-shops" label="Наши проекты" className="hide-xs hide-sm hide-md" />
        <MenuLink to="/faq" label="FAQ" className="hide-xs hide-sm hide-md" />
        <MenuLink to="/contacts" label="Контакты" className="hide-xs hide-sm hide-md hide-xl hide-xxl" />
        <MenuLink to="/reviews" label="Отзывы" className="hide-xs hide-sm hide-md" />
        <MenuLink to="/about-us" label="О нас" className="hide-xs hide-sm hide-md" />
      </MenuLinkDropdown>
    </>
  )
}

export default Menu