import { useState } from 'react'
import SubTab from '../../components/SubTab'
import useTitle from "../../hooks/useTitle"
import useCanonical from "../../hooks/useCanonical"

const ShippingAndPaymentView = () => {
  useTitle('Доставка и оплата')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'shipping-and-payment')

  const [activeTab, setActiveTab] = useState(1)
  const [activeSubtab, setActiveSubtab] = useState(null)

  return (
    <div className="container">
      <div className="heading-h1">Доставка и оплата</div>
      <div className="tabs-container">
        <div className="tabs">
          <div className={`tab-link ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Доставка</div>
          <div className={`tab-link ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Оплата</div>
          <div className={`tab-link ${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>Возврат</div>
        </div>
        <div className="tabs-content">
          <div className={`subtabs-container ${activeTab != 1 ? 'display-none' : ''}`}>
            <SubTab active={activeSubtab == 1} toggle={() => setActiveSubtab(activeSubtab == 1 ? null : 1)} heading="Курьерская доставка">
              <p>Мы осуществляем курьерскую доставку по России. Стоимость будет рассчитана автоматически при оформлении заказа в корзине (при стоимости заказа от 1500 рублей доставка по Москве бесплатная).</p>
            </SubTab>
            <SubTab active={activeSubtab == 2} toggle={() => setActiveSubtab(activeSubtab == 2 ? null : 2)} heading="Сроки курьерской доставки">
              <p>При оформлении заказа до 14:00, мы доставим его на следующий рабочий день по Москве, МО, Санкт-Петербургу, ЛО и городам ЦФО (Белгород, Брянск, Калуга, Воронеж, Орёл и другие). Сроки доставки в другие регионы будут рассчитаны автоматически в корзине.</p>
              <p>При заказе до 14:00 ваш заказ будет передан в курьерскую службу в этот же день. При заказе после 14:00 – на следующий.</p>
            </SubTab>
            <SubTab active={activeSubtab == 3} toggle={() => setActiveSubtab(activeSubtab == 3 ? null : 3)} heading="Самовывоз из кофеен в Москве">
              <p>Бесплатный самовывоз по Москве из кофеен</p>
              <p>При заказе до 14:00 ваш заказ будет передан в кофейню на следующий рабочий день. При заказе после 14:00 – через день.</p>
              <p>После доставки заказа – вы получите смс-уведомление.</p>
              <p>rockets.concept store<br />
              Тверской бульвар, 3 (Rockets Concept Store)<br />
              пн-вс с 9:00 до 23:00</p>
              <p>rockets.concept store<br />
              ул. Валовая, 35 (Rockets Concept Store)<br />
              пн-вс с 9:00 до 23:00</p>
              <p>Cappuccino Kids «Москино Салют»<br />
              ул. Кедрова, 14, корп. 3<br />
              с 10:00 до 22:00<br />
              Без выходных</p>
              <p>Cappuccino Kids «РТИ»<br />
              ул. Электрозаводская, 27с9<br />
              пн-чт 9:00-18:00<br />
              пт 9:00-17:00<br />
              сб-вс – выходной</p>
              <p>rockets.coffee shop «Привоз»<br />
              ул. Авиаконструктора Миля, 3А, м. Жулебино<br />
              ежедневно 8:30-22:00</p>
              <p>При получении заказа вы можете бесплатно получить фильтр-кофе.</p>
            </SubTab>
            <SubTab active={activeSubtab == 4} toggle={() => setActiveSubtab(activeSubtab == 4 ? null : 4)} heading="Самовывоз из ПВЗ в России и СНГ">
              <p>Вы можете получить заказ в ПВЗ транспортной компании в любом регионе России и странах СНГ. Срок доставки зависит от региона и автоматически рассчитывается в корзине при оформлении заказа.</p>
            </SubTab>
          </div>
          <div className={`subtabs-container ${activeTab != 2 ? 'display-none' : ''}`}>
            <SubTab active={activeSubtab == 5} toggle={() => setActiveSubtab(activeSubtab == 5 ? null : 5)} heading="Условия оплаты">
              <p>У нас нет минимальной суммы заказа. Оплатить заказ можно онлайн на сайте банковской картой или через систему быстрых платежей.</p>
              <p>Юридическим лицам пришлём счёт на оплату сразу после оформления заказа.</p>
            </SubTab>
            <SubTab active={activeSubtab == 6} toggle={() => setActiveSubtab(activeSubtab == 6 ? null : 6)} heading="Безопасность">
              <p>Банковские платежи выполняются через сервис CloudPayments</p>
              <p>PCI DSS</p>
              <p>Компания CloudPayments сертифицирована по международному стандарту безопасности платежных систем PCI DSS Level 1 версии 3.2.</p>
              <p>Сертификационный аудит проходит ежегодно.</p>
              <p>Для поддержки качества и безопасности услуг, в компании работают 30 человек: есть отделы разработки, тестирования, эксплуатации и поддержки. Все сотрудники регулярно проходят обучение, имеют большой опыт работы с банковскими и процессинговыми системами, являются экспертами в области технологий и безопасности.</p>
              <p>3-D Secure</p>
              <p>Использование технологии защиты платежей 3-D Secure позволяет обезопасить наших клиентов от мошеннических операций с использованием ворованных карт.</p>
              <p>Шифрование данных</p>
              <p>Для защиты данных покупателя используется шифрование на транспортном уровне — TLS 1.2 и на прикладном уровне алгоритмом RSA с длиной ключа 2048 бит.</p>
            </SubTab>
          </div>
          <div className={`subtabs-container ${activeTab != 3 ? 'display-none' : ''}`}>
            <SubTab active={activeSubtab == 7} toggle={() => setActiveSubtab(activeSubtab == 7 ? null : 7)} heading="Вариант возврата">
              <p>Если вы не будете удовлетворены качеством или вкусом продукта, мы вернем вам деньги.</p>
              <p>Мы готовы предоставить вам консультации по различным сортам кофе и по методам заваривания. Для этого позвоните по телефону 8 800 777-04-14 или напишите на почту: shop@rockets.coffee</p>
              <p>Возврат денежных средств осуществляется на ваш счёт. Срок возврата зависит от банка, который выпустил вашу карту. Обычно это происходит моментально, но могут быть исключения.</p>
            </SubTab>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShippingAndPaymentView