import { useGetPagesCountriesQuery, useGetProductsQuery } from '../features/backend/backendApiSlice'
import { Link, useParams } from 'react-router-dom'
import Map from '../components/Map'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import Product from '../components/Product'
import useTitle from "../hooks/useTitle"
import useCanonical from "../hooks/useCanonical"
import NotFound from '../components/NotFound'
import { countries } from '../data/data'

const CountryView = () => {
  const { data, isError, isLoading, isSuccess } = useGetPagesCountriesQuery()
  const { data: productsAll, isSuccess: isSuccessProducts } = useGetProductsQuery()
  
  const { slug } = useParams()

  const country = countries.find(country => country.slug == slug)

  const page = data ? data.find((page) => page.slug == slug) : null

  // useTitle(page?.title)
  useTitle(country?.name)
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'country/' + slug)

  const products = productsAll ? productsAll.filter(product => {
    const attribute = product.attributes.find(attribute => attribute.attribute.slug == 'country')
    if (!attribute) return false
    return attribute.options.find(option => option.slug == slug)
  }) : []

  if (isSuccess && isSuccessProducts && !page && products.length == 0) {
    return <NotFound />
  }

  return (
    <div className="container">
      <div className="heading-h1">{country?.name}</div>
      <div className="tabs-container mb-40">
        <div className="tabs">
          {isSuccess && data.map((page) => (
            <Link
              key={page.id}
              to={`/country/${page.slug}`}
              className={`tab-link ${page.slug == slug ? 'active' : ''}`}
            >{page.title}</Link>
          ))}
        </div>
        <div className="tabs-content">
          {isError && <div>Произошла ошибка при загрузке страницы</div>}
          {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
          {isSuccess && <div dangerouslySetInnerHTML={{ __html: page?.content }}></div>}
        </div>
      </div>

      {products.length > 0 && <div className="styled-splide-container mb-40">
        <Splide options={{
          perPage: 3,
          perMove: 1,
          gap: '15px',
          // pagination: false,
          breakpoints: { 991: { perPage: 1 } }
        }}>
          {products.map((product) => (
            <SplideSlide key={product.id}>
              <Product product={product} />
            </SplideSlide>
          ))}
        </Splide>
      </div>}

      <Map />
    </div>
  )
}

export default CountryView