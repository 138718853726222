import { useRef, useEffect } from 'react'

const useCanonical = url => {
  const documentDefined = typeof document !== 'undefined'
  const originalCanonical = useRef(documentDefined ? document.querySelector('link[rel="canonical"]') : null)

  useEffect(() => {
    if (!documentDefined) return
    
    if (url) {
      let canonical = document.querySelector('link[rel="canonical"]')
      if (!canonical) {
        canonical = document.createElement('link')
        document.head.appendChild(canonical)
      }
      canonical.rel = 'canonical'
      canonical.href = url
    }

    return () => {
      if (originalCanonical.current) {
        document.querySelector('link[rel="canonical"]').href = originalCanonical.current.href
      }
    }
  }, [url])
}

export default useCanonical