import { useCallback, useEffect } from 'react'
import useForm from '../../hooks/useForm'
import FormFieldPhone from '../../components/Form/FormFieldPhone'
import FormFieldText from '../../components/Form/FormFieldText'
import { checkPhone } from '../../utils/api'
import { Link } from 'react-router-dom'

const AuthFormStep1 = ({ active, setStep, tab, setPhone, setPhoneCode, setPhoneNumber, setPhoneCountry, setFirstName }) => {
  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultiple,
    handleSubmit,
    isSubmitting,
    error,
    setError,
  } = useForm({
    phoneCode: "+7",
    phoneNumber: "___ ___ __ __",
    phoneCountry: "ru",
  }, {
    phone: (value) => fields?.phoneNumber?.includes("_"),
    firstName: (value) => tab == 'register' && value?.length < 1,
  }, async (fields, setError) => {
    const phone = (`${fields.phoneCode}${fields.phoneNumber}`).replaceAll(' ', '')
    const response = await checkPhone(phone, tab)

    if (response?.error) {
      if (response.error == 'Пользователь не найден') {
        setError('Учетная запись не найдена. Пожалуйста, зарегистрируйтесь')
      } else {
        setError('Произошла ошибка при отправке SMS-кода')
      }
    } else if (response?.success === true) {
      setPhone(phone)
      setPhoneCode(fields.phoneCode)
      setPhoneNumber(fields.phoneNumber)
      setPhoneCountry(fields.phoneCountry)
      setFirstName(fields.firstName)
      setStep(2)
    }
  })

  useEffect(() => {
    setError(null)
  }, [tab])

  return (
    <div className={`auth-form-container ${!active ? 'display-none' : ''}`}>
      <div className="auth-form">
        <div className="auth-form-heading">{tab == 'login' ? 'Вход' : <Link to="/login">Вход</Link>} / {tab == 'register' ? 'Регистрация' : <Link to="/register">Регистрация</Link>}</div>
        <p>Введите номер телефона на который мы отправим SMS-сообщение с кодом подтверждения</p>
        <form onSubmit={handleSubmit}>
          {error && <div className="form-error">{error}</div>}
          <div className="form-fields-container-1col">
            <FormFieldPhone
              phoneCode={fields?.phoneCode ?? ''}
              phoneNumber={fields?.phoneNumber ?? ''}
              isInvalid={invalidFields.phone}
              setPhoneCode={useCallback((phoneCode, mask) => {
                setFieldMultiple({
                  phoneCode: phoneCode,
                  phoneNumber: mask,
                })
              }, [])}
              setPhoneNumber={setFieldMemoized('phoneNumber')}
              validateField={validateFieldMemoized('phone')}
              setPhoneCountry={setFieldMemoized('phoneCountry')}
            />
            <FormFieldText
              value={fields.firstName ?? ''}
              isInvalid={invalidFields.firstName}
              placeholder={'Имя'}
              onChange={setFieldMemoized('firstName')}
              validateField={validateFieldMemoized('firstName')}
              className={tab == 'login' ? 'display-none' : ''}
            />
            <button type="submit" className={`button-big-primary-wide ${isSubmitting ? 'disabled' : ''}`}>
              {isSubmitting ? 'Загрузка...' : 'Продолжить'}
            </button>
            <div className="auth-form-user-agreement">Регистрируясь на сайте <Link to="/">rockets.coffee</Link> вы принимаете <Link to="/user-agreement">Пользовательское соглашение</Link></div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AuthFormStep1