import { useState } from 'react'
import { useGetCoursesQuery } from '../features/backend/backendApiSlice'
import { Link } from 'react-router-dom'
import useTitle from "../hooks/useTitle"
import useCanonical from "../hooks/useCanonical"

const EducationView = () => {
  useTitle('Обучение')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'education-all')

  const [activeTab, setActiveTab] = useState(1)

  const { data, isError, isLoading, isSuccess } = useGetCoursesQuery()

  let categories = []
  if (isSuccess) {
    const categoriesIds = [...new Set(data.map(course => course?.course_category?.id))]
    categories = categoriesIds.map(id => data.find(course => course?.course_category?.id === id)?.course_category).filter(category => category)
  }

  return (
    <div className="container">
      <div className="heading-h1">Курсы и мастер-классы</div>
      <div className="tabs-container">
        <div className="tabs">
          {categories.map(category => (
            <div className={`tab-link ${activeTab == category.id ? 'active' : ''}`} key={category.id} onClick={() => setActiveTab(category.id)}>{category.name}</div>
          ))}
        </div>
        <div className="tabs-content">
          {isError && <div>Произошла ошибка при загрузке курсов</div>}
          {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
          {isSuccess && (
            <>
              {data.length > 0 ? <div className="courses-container">
                {data.filter(course => course.category_id === activeTab).map((course) => (
                  <div className="course-item" key={course.id}>
                    <div className="course-name">{course.name}</div>
                    <div className="course-info">
                      <div className="course-info__meta">
                        <div className="course-info__duration">{course.duration}</div>
                        <div className="course-info__price">{course.price} <span className="commissioner-400">₽</span></div>
                        <div className="course-info__link">
                          <Link to={`/education-all/${course.slug}`}>Записаться</Link>
                        </div>
                      </div>
                      <div className="course-info__description-short">{course.description_short}</div>
                      <div className="course-info__link--mobile">
                        <Link to={`/education-all/${course.slug}`}>Записаться</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div> : <p>Пока нет курсов</p>}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EducationView