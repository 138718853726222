import { useState, useEffect } from 'react'
import { useGetPagesKnowledgeQuery } from '../features/backend/backendApiSlice'
import { Link } from 'react-router-dom'
import useTitle from "../hooks/useTitle"
import useCanonical from "../hooks/useCanonical"

const KnowledgeView = () => {
  useTitle('Как заварить и рецепты')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'knowledge')

  const { data, isError, isLoading, isSuccess } = useGetPagesKnowledgeQuery()
  const [activeTab, setActiveTab] = useState(null)

  const categories = data ? [...new Set(data.map((page) => page.page_category.name))] : []
  const count = data ? data.filter((page) => page.page_category.name == activeTab).length : 0
  const formatCount = count == 1 ? 'статья' : count > 1 && count < 5 ? 'статьи' : 'статей'

  useEffect(() => {
    if (categories.length > 0) {
      setActiveTab(categories[0])
    }
  }, [data])

  return (
    <div className="container">
      <div className="heading-h1">Статьи о кофе и<br />способы<br />заваривания</div>
      <div className="tabs-container">
        <div className="tabs">
          <p className="tabs-info">Познавательные статьи о кофе и пошаговые инструкции для разных способов заваривания</p>
          {categories.map((category, index) => (
            <div key={index} className={`tab-link ${activeTab == category ? 'active' : ''}`} onClick={() => setActiveTab(category)}>{category}</div>
          ))}
        </div>
        <div className="tabs-content">
          <div className="knowledge-heading">
            <div className="heading-h2">Актуальная информация о кофейной культуре и способах заваривания</div>
            <div>{isSuccess && `${count} ${formatCount}`}</div>
          </div>
          {isError && <div>Произошла ошибка при загрузке статей</div>}
          {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
          {isSuccess && (
            <>
              {data.length > 0 ? <div className="knowledge-container">
                {data.filter((page) => page.page_category.name == activeTab).map((page) => (
                  <Link to={`/knowledge/${page.slug}`} key={page.id} className="knowledge-item">
                    <div className="knowledge-item__thumbnail">
                      {page.thumbnail && <img src={import.meta.env.VITE_BACKEND_URL + page.thumbnail} alt="" />}
                    </div>
                    <div className="knowledge-item__title">{page.title}</div>
                  </Link>
                ))}
              </div> : <p>Статей в данной категории пока нет</p>}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default KnowledgeView