import { useState, useRef, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetUserQuery, useGetProductsQuery, useGetPagesKnowledgeQuery } from "../features/backend/backendApiSlice"
import useProduct from "../hooks/useProduct"
import ProductSpecs from "./ProductSpecs"
import ProductBrewingMethods from "./ProductBrewingMethods"
import { Link } from "react-router-dom"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import BrewingMethods from "./BrewingMethods"
import Review from "./Review"
import ProfileModalReview from "./Profile/ProfileModalReview"
import { SLUG_KNOWLEDGE_CAT_ARTICLES } from "../utils/enums"
import { recentlySeenAdded, selectRecentlySeen } from "../features/recentlySeen/recentlySeenSlice"
import Product from "./Product"
import useTitle from "../hooks/useTitle"
import useDescription from "../hooks/useDescription"
import useCanonical from "../hooks/useCanonical"
import { isOnSale } from '../utils/functions'
import ProductPageImage from "./ProductPageImage"
import ProductPageImageThumbnail from "./ProductPageImageThumbnail"

const ProductPage = ({ product }) => {
  useTitle(product.title)
  useDescription(product.meta_description)
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'products/' + product.slug)

  const { data: user } = useGetUserQuery()
  const { data: products, refetch } = useGetProductsQuery()
  const { data: pagesKnowledge } = useGetPagesKnowledgeQuery()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(recentlySeenAdded(product.id))
  }, [product])

  const recentlySeen = useSelector(selectRecentlySeen)

  const {
    variation,
    selectedOptions,
    openedDropdown,
    setOpenedDropdown,
    handleChangeOption,
    qty,
    handleQtyLower,
    handleQtyRaise,
    buttonFlashText,
    handleAddToCart,
    isFavorite,
    loadingIsFavorite,
    handleAddToFavorites,
    handleRemoveFromFavorites,
  } = useProduct(product)

  const sliderRef = useRef(null)

  const handleThumbnailClick = (id) => {
    setCurrentSlide(id)
    sliderRef.current.go(id)
  }

  const images = product ? product.variations.map((variation) => variation.images).flat() : []
  const [currentSlide, setCurrentSlide] = useState(0)

  const averageRating = product.reviews.length > 0 ? (product.reviews.reduce((acc, review) => acc + review.rating, 0) / product.reviews.length).toFixed(1) : 0

  const [modalOpen, setModalOpen] = useState(null)
  const closeModal = useCallback(() => setModalOpen(null), [])

  return (
    <>
      <div className="container">
        <ProfileModalReview
          isOpen={modalOpen == 'review'}
          onClose={closeModal}
          product={product}
          callback={refetch}
        />
        <div className="product-main-section">
          <div className="product-images-container">
            <div className="product-images">
              <div className="product-topbar">
                <div className="product-topbar-labels">
                  {product.tags.map((tag) => (
                    <div key={tag.id} className="product-topbar-label" style={{backgroundColor: tag.color_badge, color: tag.color_text}}>{tag.name}</div>
                  ))}
                </div>
                {user?.customer && <div className="product-topbar-favorite" onClick={() => isFavorite ? handleRemoveFromFavorites() : handleAddToFavorites()}>
                  {loadingIsFavorite
                    ? <img src="/images/loading.svg" alt="" />
                    : (isFavorite ? <img src="/images/utils/heart-filled.svg" alt="" /> : <img src="/images/utils/heart-empty.svg" alt="" />)}
                </div>}
              </div>
              <div className="styled-splide-container-pagination">
                <Splide
                  ref={sliderRef}
                  onMove={(splide, index, prev, dest) => setCurrentSlide(index)}
                  options={{
                    perPage: 1,
                    perMove: 1,
                    // type: 'loop',
                  }}
                >
                  {images && images.map((image) => (
                    <SplideSlide key={image.id}>
                      <ProductPageImage fileName={image.path} alt={product.title} />
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </div>
            <div className="product-thumbnails">
              {images && images.map((image, index) => (
                <div
                  key={index}
                  className={`product-thumbnail ${currentSlide === index ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <ProductPageImageThumbnail fileName={image.path} alt={product.title} />
                </div>
              ))}
            </div>
          </div>
          <div className="product-info">
            <div className="product-breadcrumbs">
              <Link to="/">rockets.coffee</Link> {product.categories.map((category, key) => (
                <>/ <Link key={key} to={`/catalog/${category.slug}`}>{category.name}</Link> </>
              ))}/ {product.name}
            </div>
            <div className="product-title">{product.name}</div>
            <div className="product-description" dangerouslySetInnerHTML={{ __html: product.description_short }} />
            <ProductSpecs product={product} className="big" />
            <ProductBrewingMethods product={product} />
            <div className="product-options">
              <div className="product-options-controls">
                {selectedOptions.map((option, key) => <div key={key} className="catalog-product-option" onClick={() => setOpenedDropdown(prevState => prevState === option.attribute_id ? null : option.attribute_id)}>
                  <div className="dropdown-container">
                    <div className="dropdown-label">
                      <div>{option.name}</div>
                      <img src="/images/utils/arrow-down.svg" />
                    </div>
                    <div className={`dropdown-content ${openedDropdown == option.attribute_id ? 'active' : ''}`}>
                      {product.attributes.find(elem => elem.attribute.id === option.attribute_id).options.map((elem, key) => (
                        <a key={key} onClick={() => handleChangeOption(option.attribute_id, elem.name)}>{elem.name}</a>
                      ))}
                    </div>
                  </div>
                </div>)}
                <div className="catalog-product-option catalog-product-option__qty">
                  <div onClick={handleQtyLower}>-</div>
                  <div>{qty}</div>
                  <div onClick={handleQtyRaise}>+</div>
                </div>
              </div>
              {variation !== false && <div className="product-price">{isOnSale(variation) ? <><s>{variation?.price / 100} <span className="commissioner-400">₽</span></s> {variation?.price_sale / 100}</> : variation?.price / 100} <span className="commissioner-700">₽</span></div>}
            </div>
            <div className={`catalog-product-buy-button ${(variation === false || product.in_stock == 0) ? 'disabled' : ''}`} onClick={handleAddToCart}>
              <div>
                {variation === false
                  ? `Недоступно`
                  : (product.in_stock == 0
                    ? `Нет в наличии`
                    : (buttonFlashText ? 'Добавлено' : 'Купить'))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="product-section">
          <div className="product-heading">Подробнее</div>
          <div className="product-section-description" dangerouslySetInnerHTML={{ __html: product.description }} />
        </div>
        <div className="product-section">
          <BrewingMethods product={product} />
        </div>
        <div className="product-section">
          <div className="tabs-container">
            <div className="tabs">
              <div className="product-heading">Отзывы</div>
            </div>
            <div className="tabs-content">
              {product.reviews.length > 0 && <div className="reviews-heading">
                <div className="reviews-heading__rating">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <img
                      key={rating}
                      src={`/images/utils/star-${averageRating >= rating ? 'blue' : 'gray'}.svg`}
                    />
                  ))}
                </div>
                <div className="reviews-heading__average">{averageRating}</div>
              </div>}
              <div className="reviews-container">
                {product.reviews.length > 0
                  ? product.reviews.map((review) => (
                    <Review key={review.id} review={review} />
                  ))
                  : <p>Отзывов пока нет</p>}
                {user?.customer && <div>
                  <button className="button-big-primary-wide" onClick={() => setModalOpen('review')}>Оставить отзыв</button>
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="product-section">
          <div className="tabs-container">
            <div className="tabs">
              <div className="product-heading">Культура кофе</div>
            </div>
            <div className="tabs-content product-knowledge-cards">
              {pagesKnowledge && pagesKnowledge.filter(page => page.page_category.slug == SLUG_KNOWLEDGE_CAT_ARTICLES) && pagesKnowledge.filter(page => page.page_category.slug == SLUG_KNOWLEDGE_CAT_ARTICLES).map((page) => (
                <Link to={`/knowledge/${page.slug}`} key={page.id} className="product-knowledge-card">
                  <div className="product-knowledge-card-image">
                    <img src={import.meta.env.VITE_BACKEND_URL + page.thumbnail} alt={page.title} />
                  </div>
                  <div className="product-knowledge-card-content">
                    <div className="product-knowledge-card-title">{page.title}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="recently-seen">
          <div className="product-heading">Недавно просмотренное</div>
          <div className="container">
            {products && recentlySeen.length > 0 && (
              <div className="styled-splide-container">
                <Splide options={{
                  perPage: 3,
                  perMove: 1,
                  gap: '15px',
                  pagination: true,
                  breakpoints: { 991: { perPage: 2 }, 768: { perPage: 1 } }
                }}>
                  {recentlySeen.map((data) => (
                    products.find(product => product.id == data.id) && (
                      <SplideSlide key={data.id}>
                        <Product product={products.find(product => product.id == data.id)} />
                      </SplideSlide>
                    )
                  ))}
                </Splide>
              </div>
            )}
          </div>
        </div>
    </>
  )
}

export default ProductPage